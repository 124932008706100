import React from 'react';
import type { WidgetProps } from '@wix/yoshi-flow-editor';

import { SocialGroupsApp } from 'common/components/SocialGroupsApp';
import { useShadowStyles } from 'common/hooks/useShadowStyles';

import { COMPONENT } from 'settings/consts';

import type { IVMProps } from '../../../vm/types';

import { Group } from './Group';
import { GROUP_WRAPPER } from './dataHooks';

COMPONENT.name = 'GroupPage';

export default function Widget(props: WidgetProps<IVMProps>) {
  const shadowStyles = useShadowStyles();

  return (
    <SocialGroupsApp {...props}>
      <div data-hook={GROUP_WRAPPER} ref={shadowStyles.onRefChange}>
        <Group />
      </div>
    </SocialGroupsApp>
  );
}
