import React, { useState } from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { Spinner, ButtonPriority } from 'wix-ui-tpa';
import type { ImageFocalPoint } from 'wix-ui-tpa';
import { Image as ImageIcon } from '@wix/wix-ui-icons-common/on-stage';

import { Button } from 'common/components/Button';

import { FocalPointSelect } from './FocalPointSelect';

import { st, classes } from './LogoEditor.st.css';

interface ILogoEditorProps {
  enabled?: boolean;
  loading?: boolean;
  focalPoint: ImageFocalPoint;

  onLogoChange(file: File): void;
  onFocalPointChange(focalPoint: ImageFocalPoint): void;

  onSave?(): void;
  onCancel?(): void;
}

export function LogoEditor(props: ILogoEditorProps) {
  const $input = React.useRef<HTMLInputElement>(null);

  const { t } = useTranslation();
  const [isActive, setIsActive] = useState(false);

  if (!props.enabled) {
    return (
      <>
        <div className={classes.root}>
          <div className={classes.editActions}>
            <Button
              className={classes.changePhotoButton}
              onClick={handleUploadButtonClick}
              prefixIcon={<ImageIcon />}
              upgrade
            >
              <span className={classes.changePhotoButtonLabel}>
                {t('groups-web.reposition.change-photo')}
              </span>
            </Button>
          </div>
        </div>
        <input
          hidden
          data-hook="image-input"
          type="file"
          multiple={false}
          ref={$input}
          onChange={handleChange}
          accept="image/*"
        />
      </>
    );
  }

  return (
    <div className={classes.root}>
      <FocalPointSelect
        value={props.focalPoint}
        onActiveChange={setIsActive}
        onChange={props.onFocalPointChange}
      />

      {!isActive && (
        <div className={classes.actions}>
          <Button
            upgrade
            onClick={props.onCancel}
            className={classes.cancelButton}
            priority={ButtonPriority.basicSecondary}
          >
            {t('groups-web.reposition.cancel')}
          </Button>
          <Button
            upgrade
            disabled={props.loading}
            onClick={props.onSave}
            className={classes.saveButton}
            priority={ButtonPriority.basic}
          >
            {props.loading ? (
              <Spinner diameter={20} />
            ) : (
              t('groups-web.reposition.save')
            )}
          </Button>
        </div>
      )}
    </div>
  );

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const [image] = Array.from(event.target.files as FileList);

    props.onLogoChange(image);
  }

  function handleUploadButtonClick() {
    $input.current!.click();
  }
}
