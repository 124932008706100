import React from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { ListItem } from 'wui/ListItem';
import { ListItemAvatar } from 'wui/ListItemAvatar';
import { ListItemText } from 'wui/ListItemText';
import { Skeleton } from 'wui/Skeleton';

export const ProfileListItemSkeleton: React.FC = () => {
  const { isMobile } = useEnvironment();

  return (
    <ListItem>
      <ListItemAvatar>
        <Skeleton variant="circular" width={36} height={36} />
      </ListItemAvatar>
      <ListItemText title={<Skeleton width={isMobile ? '60%' : '45%'} />} />
    </ListItem>
  );
};

ProfileListItemSkeleton.displayName = 'ProfileListItemSkeleton';
