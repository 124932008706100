import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { useSelector } from 'react-redux';
import uuid from 'uuid/v4';

import { Text, TextTypography } from 'wix-ui-tpa';

import { SideDrawer } from 'common/components/SideDrawer';
import { Spinner } from 'common/components/Spinner';
import { Block, BlockFlow } from 'common/components/Block';
import { useDidUpdate } from 'common/hooks';

import { useController } from 'common/context/controller';

import {
  selectAreQuestionsLoading,
  selectAreQuestionsUpdating,
  selectGroupQuestions,
} from 'store/selectors';

import { EditQuestions } from './EditQuestions';
import { MEMBERSHIP_QUESTIONS } from './dataHooks';

import { IQuestion } from './types';

import { classes as tabsClasses } from '../../Tabs.st.css';
import { classes } from './GroupQuestions.st.css';

interface IMemberPermissionsProps {
  groupId: string;
  isOpen: boolean;
  onClose(): void;
}

export function GroupQuestions(props: IMemberPermissionsProps) {
  const { groupId, isOpen, onClose } = props;
  const { group$ } = useController();

  const { t } = useTranslation();

  const savedQuestions = useSelector(selectGroupQuestions(groupId));
  const isUpdating = useSelector(selectAreQuestionsUpdating(groupId));
  const loading = useSelector(selectAreQuestionsLoading(groupId));

  const [questions, setQuestions] = React.useState<IQuestion[]>([]);

  const hasInvalidQuestion = questions.some((q) => q.invalid);

  React.useEffect(() => {
    group$.fetchQuestions(groupId);
  }, []);

  React.useEffect(() => {
    if (isUpdating) {
      return;
    }
    setQuestions(savedQuestions);
  }, [savedQuestions, isUpdating]);

  useDidUpdate(() => {
    if (!isUpdating) {
      handleClose();
    }
  }, [isUpdating]);

  return (
    <SideDrawer
      isOpen={isOpen}
      enableScrollLock={false}
      onClose={handleClose}
      title={t('groups-web.group.settings.questions.edit')}
      cancelButtonProps={{
        onClick: handleCancel,
        label: t('groups-web.cancel'),
      }}
      submitButtonProps={{
        loading: isUpdating,
        disabled: isUpdating || hasInvalidQuestion,
        onClick: handleSave,
        label: t('groups-web.save'),
      }}
    >
      <div className={classes.root}>
        <Block
          flow={BlockFlow.row}
          className={classes.questions}
          data-hook={MEMBERSHIP_QUESTIONS}
        >
          <Text
            tagName="p"
            className={tabsClasses.bodyText}
            typography={TextTypography.runningText}
          >
            {t('groups-web.group.settings.moderate.questions.caption')}
          </Text>
          {loading ? (
            <Spinner bw={true} offset="S" />
          ) : (
            <EditQuestions
              groupId={groupId}
              questions={questions}
              onAdd={addQuestion}
              onRemove={removeQuestion}
              onBlur={handleBlurQuestion}
              onChange={updateQuestion}
            />
          )}
        </Block>
      </div>
    </SideDrawer>
  );

  function handleSave() {
    if (hasInvalidQuestion) {
      return;
    }
    group$.updateQuestions(groupId, questions);
  }

  function handleClose() {
    onClose();
  }

  function handleCancel() {
    onClose();
  }

  function addQuestion() {
    const questionId = uuid();

    setQuestions([
      ...questions,
      {
        id: questionId,
        forceFocus: true,
      },
    ]);
  }

  function removeQuestion(index: number) {
    const updatedQuestions = [...questions];
    updatedQuestions.splice(index, 1);
    setQuestions(updatedQuestions);
  }

  function updateQuestion(question: IQuestion, index: number) {
    if (question.invalid) {
      question = validateQuestion(question);
    }
    const updatedQuestions = [...questions];
    updatedQuestions[index] = question;
    setQuestions(updatedQuestions);
  }

  function validateQuestion(question: IQuestion) {
    return { ...question, invalid: !question.text };
  }

  function handleBlurQuestion(index: number) {
    const updatedQuestions = [...questions];
    updatedQuestions[index] = {
      ...validateQuestion(updatedQuestions[index]),
      forceFocus: false,
    };
    setQuestions(updatedQuestions);
  }
}
