import React from 'react';
import { useSelector } from 'react-redux';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { Breadcrumbs } from 'wix-ui-tpa';

import { selectGroupBySlug } from 'store/selectors';

import { useRouter, useCurrentStateAndParams } from 'common/router';

import { classes } from './ApplicationBreadcrumbs.st.css';

export function ApplicationBreadcrumbs() {
  const router = useRouter();
  const { t } = useTranslation();
  const { params } = useCurrentStateAndParams();

  const group = useSelector(selectGroupBySlug(params.slug));

  return (
    <Breadcrumbs
      className={classes.root}
      onClick={() => {}}
      items={[
        {
          id: 'home',
          value: t(['groups-web.breadcrumbs.home', 'Home']),
          href: router.href('home', {}, { absolute: true }),
        },
        {
          id: 'groups',
          value: t('groups-web.breadcrumbs.group-list'),
          href: router.href('groups', {}, { absolute: true }),
        },
        {
          id: 'group',
          value: group.name || t('groups-web.breadcrumbs.group-title-default'),
          href: router.href('group.discussion.feed', {}, { absolute: true }),
        },
      ]}
    />
  );
}
