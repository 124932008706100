import React from 'react';
import { useSelector } from 'react-redux';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { CSSTransitionClassNames } from 'react-transition-group/CSSTransition';
import { useBi, useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { Tabs, TabsSkin, TabsVariant } from 'wix-ui-tpa';
import { groupNotificationsSettingsSave } from '@wix/bi-logger-groups/v2';

import {
  NotificationChannel,
  NotificationSettings,
} from '@wix/ambassador-social-groups-v1-notification-settings/types';

import { SideDrawer } from 'common/components/SideDrawer';
import { useController } from 'common/context/controller';
import { useDidUpdate } from 'common/hooks';

import {
  selectAllNotificationsSettings,
  selectAreNotificationsSettingsLoading,
  selectAreNotificationsSettingsUpdating,
} from 'store/selectors';
import type { INotificationSettings } from 'store/groups/types';

import { GROUP_ACTIONS_SIDE_PANEL } from '../dataHooks';

import { ChannelSettings } from './ChannelSettings/ChannelSettings';

import { classes } from './NotificationSettings.st.css';
import { bootstrapBIChanges } from './bi';

interface INotificationSettingsProps {
  groupId: string;
  isOpen: boolean;

  onClose(): void;
}

export function NotificationSettingsComponent(
  props: INotificationSettingsProps,
) {
  const { group$ } = useController();

  const bi = useBi();
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();

  const [activeChannel, setActiveChannel] = React.useState<NotificationChannel>(
    NotificationChannel.WEB,
  );

  const isLoading = useSelector(
    selectAreNotificationsSettingsLoading(props.groupId),
  );
  const isUpdating = useSelector(
    selectAreNotificationsSettingsUpdating(props.groupId),
  );

  const savedNotificationSettings = useSelector(
    selectAllNotificationsSettings(props.groupId),
  );

  const [notificationSettings, setNotificationSettings] =
    React.useState<INotificationSettings>({});

  React.useEffect(() => {
    setNotificationSettings({
      ...savedNotificationSettings,
      ...notificationSettings,
    });
  }, [savedNotificationSettings]);

  React.useEffect(() => {
    if (!savedNotificationSettings[activeChannel] && props.isOpen) {
      group$.fetchNotificationsSettings(props.groupId, activeChannel);
    }
  }, [activeChannel, props.isOpen]);

  useDidUpdate(() => {
    if (!isUpdating) {
      handleClose();
    }
  }, [isUpdating]);

  const tabs = [
    {
      id: NotificationChannel.WEB,
      title: t('groups-web.notifications.website'),
    },
    {
      id: NotificationChannel.EMAIL,
      title: t('groups-web.notifications.email'),
    },
    {
      id: NotificationChannel.MOBILE,
      title: t('groups-web.notifications.mobile'),
    },
  ];

  return (
    <SideDrawer
      isOpen={props.isOpen}
      onClose={handleClose}
      title={t('groups-web.notifications')}
      data-hook={GROUP_ACTIONS_SIDE_PANEL}
      cancelButtonProps={{
        onClick: handleClose,
        label: t('groups-web.cancel'),
      }}
      submitButtonProps={{
        label: t('groups-web.save'),
        onClick: handleSave,
        disabled: isLoading || isUpdating,
        loading: isLoading,
      }}
    >
      <div className={classes.root}>
        <Tabs
          className={classes.tabs}
          variant={TabsVariant.fullWidth}
          activeTabIndex={tabs.findIndex((t) => t.id === activeChannel)}
          onTabClick={(index) => setActiveChannel(tabs[index].id)}
          skin={isMobile ? TabsSkin.clear : TabsSkin.fullUnderline}
          items={tabs}
        />
        <SwitchTransition>
          <CSSTransition
            timeout={300}
            key={activeChannel}
            classNames={classes as CSSTransitionClassNames}
          >
            <div className={classes.content}>
              <ChannelSettings
                settings={notificationSettings[activeChannel] || []}
                channel={activeChannel}
                onChange={handleActiveChannelSettingsChange}
              />
            </div>
          </CSSTransition>
        </SwitchTransition>
      </div>
    </SideDrawer>
  );

  function handleClose() {
    setActiveChannel(NotificationChannel.WEB);
    setNotificationSettings(savedNotificationSettings);
    props.onClose();
  }

  function handleActiveChannelSettingsChange(
    newSettings: NotificationSettings[],
  ) {
    setNotificationSettings((notificationSettings) => ({
      ...notificationSettings,
      [activeChannel]: newSettings,
    }));
  }

  function handleSave() {
    Object.keys(notificationSettings).forEach((channel) => {
      group$.updateNotificationsSettings(
        props.groupId,
        channel as NotificationChannel,
        notificationSettings[channel as NotificationChannel] || [],
      );
    });

    bootstrapBIChanges(savedNotificationSettings, notificationSettings).forEach(
      (channel) => {
        bi.report(
          groupNotificationsSettingsSave({
            ...channel,
            origin: 'save_btn',
            userEntry: 'site',
            groupId: props.groupId,
          }),
        );
      },
    );
  }
}
