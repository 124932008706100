import React from 'react';
import { useSelector } from 'react-redux';
import {
  useBi,
  useEnvironment,
  useExperiments,
  useTranslation,
} from '@wix/yoshi-flow-editor';
import {
  groupActionClick,
  groupsRequestJoinAGroup,
} from '@wix/bi-logger-groups/v2';
import { IconButton } from 'wix-ui-tpa';
import type { TPAComponentProps } from 'wix-ui-tpa/dist/src/types';

import { Share as ShareIcon } from '@wix/wix-ui-icons-common/on-stage';

import {
  selectGroupPrivacyStatus,
  selectGroupSlug,
  selectIsGroupSecret,
} from 'store/selectors';

import { useHref } from 'common/router';
import { BIUserEntry } from 'common/bi-logger/types';

import { ShareDialog } from 'wui/ShareDialog';

import { GroupMembershipButton } from 'common/components/GroupMembership';
import { InviteMembers } from '../InviteMembers';
import { AddInviteMembers } from '../AddInviteMembers';
import { GroupActions } from '../GroupActions';

import { GroupMeta } from './GroupMeta';

import { st, classes } from './GroupDetails.st.css';

interface IGroupDetailsProps extends TPAComponentProps {
  groupId: string;
}

export function GroupDetails(props: IGroupDetailsProps) {
  const { groupId } = props;

  const bi = useBi();
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const { experiments } = useExperiments();

  const slug = useSelector(selectGroupSlug(groupId));
  const isSecretGroup = useSelector(selectIsGroupSecret(groupId));
  const privacyStatus = useSelector(selectGroupPrivacyStatus(props.groupId));

  const [isOpen, setIsOpen] = React.useState(false);

  const groupUrl = useHref(
    'group.discussion.feed',
    { slug },
    { absolute: true },
  );

  return (
    <div className={st(classes.root, { isMobile }, props.className)}>
      <GroupMeta groupId={groupId} />

      <div className={classes.actions}>
        {experiments.enabled('specs.groups.SplitAddMembersPermissions') ? (
          <AddInviteMembers groupId={groupId} />
        ) : (
          <InviteMembers groupId={groupId} />
        )}

        <GroupMembershipButton
          groupId={groupId}
          fullWidth={isMobile}
          bi={groupsRequestJoinAGroup({
            group_id: props.groupId,
            origin: 'group_feed_top_banner_btn',
            userEntry: BIUserEntry.SITE,
            type: (privacyStatus || '').toLowerCase(),
          })}
          className={classes.membershipButton}
        />

        {!isSecretGroup && !isMobile && (
          <IconButton
            aria-label="Share"
            data-hook="share-button"
            icon={<ShareIcon />}
            onClick={handleOpenShareModal('arrow')}
          />
        )}
        {!isMobile && (
          <GroupActions
            className={classes.settings}
            groupId={groupId}
            onShare={handleOpenShareModal('menu')}
          />
        )}
      </div>
      <ShareDialog
        isOpen={isOpen}
        data={{ url: groupUrl }}
        onClose={handleCloseShareModal}
        title={t('groups-web.group.actions.share.group')}
      />
    </div>
  );

  function handleCloseShareModal() {
    setIsOpen(false);
  }

  function handleOpenShareModal(origin: string) {
    return () => {
      setIsOpen(true);

      bi.report(
        groupActionClick({
          origin,
          action: 'share',
          group_id: groupId,
        }),
      );
    };
  }
}
