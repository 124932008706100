import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { FormikHelpers, FormikProps } from 'formik';
import { useBi } from '@wix/yoshi-flow-editor';
import { groupSettingsSave } from '@wix/bi-logger-groups/v2';

import { selectGroup, selectIsGroupUpdating } from 'store/selectors';

import { BIUserEntry } from 'common/bi-logger/types';
import { useDidUpdate } from 'common/hooks';
import { EditGroupForm, IEditableGroup } from 'common/components/EditGroupForm';
import { useController } from 'common/context/controller';

import { GroupSettingsContext } from '../../GroupSettingsContext';

interface IDetailsTabProps {
  groupId: string;

  onClose(): void;
}

export function DetailsTab(props: IDetailsTabProps) {
  const { groupId } = props;
  const { group$ } = useController();

  const bi = useBi();

  const group = useSelector(selectGroup(groupId));
  const isUpdating = useSelector(selectIsGroupUpdating(groupId));

  const groupSettings = React.useContext(GroupSettingsContext);

  const form = useRef<FormikProps<IEditableGroup>>(null);
  const [isBusy, setIsBusy] = React.useState(false);

  React.useEffect(() => {
    groupSettings.set({
      submitButtonProps: {
        onClick: form.current?.submitForm,
        loading: isBusy || isUpdating,
        disabled: isBusy || isUpdating,
      },
    });
  }, [isBusy, isUpdating]);

  useDidUpdate(() => {
    if (!isUpdating) {
      props.onClose();
    }
  }, [isUpdating]);

  return (
    <EditGroupForm
      innerRef={form}
      onSubmit={handleFormSubmit}
      onBusyStateChange={setIsBusy}
      initialValues={{
        name: group.name,
        privacyStatus: group.privacyStatus,
        coverImage: group.coverImage,
      }}
    />
  );

  function handleFormSubmit(
    values: IEditableGroup,
    helpers: FormikHelpers<IEditableGroup>,
  ) {
    group$.updateGroupInfo(groupId, values);

    bi.report(
      groupSettingsSave({
        userEntry: BIUserEntry.SITE,
        group_id: groupId,
        origin: 'save_button',
        tabName: 'details',
        type: values.privacyStatus,
        withPhoto: !!values.coverImage?.image,
        display_cover: true,
      }),
    );
  }
}
