import React from 'react';
import { useSelector } from 'react-redux';
import { useBi, useTranslation } from '@wix/yoshi-flow-editor';
import { Text, TextTypography, RadioButtonGroup } from 'wix-ui-tpa';
import { groupSettingsSave } from '@wix/bi-logger-groups/v2';

import { Role } from '@wix/ambassador-social-groups-v2-group-member/types';

import { SideDrawer } from 'common/components/SideDrawer';
import { BlackAndWhiteRadioButton } from 'common/components/BlackAndWhiteRadioButton';
import { useController } from 'common/context/controller';
import { useDidUpdate } from 'common/hooks';
import { BIUserEntry } from 'common/bi-logger/types';

import { selectGroup, selectIsGroupUpdating } from 'store/selectors';

import { classes as tabsClasses } from '../../Tabs.st.css';
import { classes } from './MemberPermissions.st.css';

interface IMemberPermissionsProps {
  groupId: string;
  isOpen: boolean;
  onClose(): void;
}

const mapMembersCanInviteValueToRole = (membersCanInviteValue?: boolean) => {
  return membersCanInviteValue ? Role.MEMBER : Role.ADMIN;
};

export function MemberPermissions(props: IMemberPermissionsProps) {
  const { t } = useTranslation();
  const bi = useBi();

  const { group$ } = useController();

  const group = useSelector(selectGroup(props.groupId));
  const isGroupUpdating = useSelector(selectIsGroupUpdating(props.groupId));

  const [membersCanInvite, setMembersCanInvite] = React.useState<boolean>();

  React.useEffect(() => {
    if (!isGroupUpdating) {
      setMembersCanInvite(!!group.settings?.membersCanInvite);
    }
  }, [group, isGroupUpdating]);

  useDidUpdate(() => {
    if (!isGroupUpdating) {
      props.onClose();
    }
  }, [isGroupUpdating]);

  return (
    <SideDrawer
      isOpen={props.isOpen}
      enableScrollLock={false}
      onClose={props.onClose}
      title={t('groups-web.group.settings.permissions')}
      cancelButtonProps={{
        onClick: props.onClose,
        label: t('groups-web.cancel'),
      }}
      submitButtonProps={{
        loading: isGroupUpdating,
        disabled: isGroupUpdating,
        onClick: handleSave,
        label: t('groups-web.save'),
      }}
    >
      <div className={classes.root}>
        <Text
          tagName="p"
          className={tabsClasses.bodyText}
          typography={TextTypography.runningText}
        >
          {t('groups-web.group.settings.permissions.caption')}
        </Text>
        <RadioButtonGroup
          name="group-permission"
          value={mapMembersCanInviteValueToRole(membersCanInvite)}
          onChange={handleOnChange}
          withSpacing
        >
          <BlackAndWhiteRadioButton
            value={Role.MEMBER}
            label={t('groups-web.group.settings.permissions.all-members')}
          />
          <BlackAndWhiteRadioButton
            value={Role.ADMIN}
            checked
            label={t(
              'groups-web.group.settings.permissions.admin-and-moderators',
            )}
          />
        </RadioButtonGroup>
      </div>
    </SideDrawer>
  );

  function handleOnChange(newValue: Role) {
    setMembersCanInvite(newValue === Role.MEMBER);
  }

  function handleSave() {
    bi.report(
      groupSettingsSave({
        userEntry: BIUserEntry.SITE,
        group_id: props.groupId,
        origin: 'save_button',
        tabName: 'permissions',
        member_toggle: membersCanInvite,
      }),
    );

    group$.updateGroupSettings(props.groupId, {
      membersCanInvite,
    });
  }
}
