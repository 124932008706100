import React from 'react';
import { useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import { debounce } from 'lodash';

import { useTranslation } from '@wix/yoshi-flow-editor';

import {
  selectSuggestedMembers,
  selectSuggestedMembersMetadata,
} from 'store/selectors';

import { useController } from 'common/context/controller';

import {
  ProfileListItemSkeleton,
  ProfileListItem,
} from 'common/components/ProfileListItem';

import { AvatarSize } from 'wix-ui-tpa';
import { List } from 'wui/List';
import { EmptyState } from 'wui/EmptyState';
import { Button } from 'wui/Button';
import { ErrorState } from 'wui/ErrorState';

interface ISuggestedMembersProps {
  groupId: string;
  selected: string[];
  search: string;

  onReset(): void;
  onSelect(memberIds: string[]): void;
}

export function SuggestedMembersList(props: ISuggestedMembersProps) {
  const { selected, search, groupId, onReset, onSelect } = props;

  const { t } = useTranslation();

  const { members$ } = useController();

  const meta = useSelector(selectSuggestedMembersMetadata);
  const members = useSelector(selectSuggestedMembers);

  const querySuggested = React.useCallback(
    debounce((search: string) => {
      members$.querySuggested(groupId, {
        nickname: search,
      });
    }, 300),
    [],
  );

  React.useEffect(() => {
    querySuggested(search);
  }, [search]);

  if (meta.error) {
    return <ErrorState onRetry={handleRetry} />;
  }

  if (meta.loading) {
    return (
      <List disablePadding>
        {Array(3)
          .fill(0)
          .map((i) => (
            <ProfileListItemSkeleton key={i} />
          ))}
      </List>
    );
  }

  if (!members.length) {
    return (
      <EmptyState
        bw
        title={t('groups-web.dialogs.add-members.search.no-result.title')}
        subtitle={t('groups-web.dialogs.add-members.search.no-result.subtitle')}
        variant="section"
        action={
          <Button onClick={onReset} bw>
            {t('groups-web.dialogs.add-members.search.no-result.button.label')}
          </Button>
        }
      />
    );
  }

  return (
    <InfiniteScroll
      initialLoad={false}
      useWindow={false}
      hasMore={meta.metadata.total! > members.length}
      loadMore={handleLoadMore}
      threshold={200}
      loader={
        <>
          <ProfileListItemSkeleton />
          <ProfileListItemSkeleton />
        </>
      }
    >
      <List disablePadding>
        {members.map((member) => (
          <ProfileListItem
            bw
            profile={member}
            selected={isMemberSelected(member.memberId as string)}
            onClick={() => handleToggle(member.memberId as string)}
            avatarProps={{
              size: AvatarSize.medium,
            }}
          />
        ))}
      </List>
    </InfiniteScroll>
  );

  function handleToggle(memberId: string) {
    const isSelected = selected.indexOf(memberId) !== -1;

    if (isSelected) {
      onSelect(props.selected.filter((id) => id !== memberId));
    } else {
      onSelect([...props.selected, memberId]);
    }
  }

  function isMemberSelected(memberId: string) {
    return selected.includes(memberId);
  }

  function handleLoadMore() {
    members$.querySuggested(
      groupId,
      {
        nickname: search,
      },
      members.length,
    );
  }

  function handleRetry() {
    members$.querySuggested(groupId, {
      nickname: search,
    });
  }
}
