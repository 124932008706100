import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { Text, TextTypography, TextField, Checkbox } from 'wix-ui-tpa';
import { Delete as DeleteIcon } from '@wix/wix-ui-icons-common/on-stage';

import { Block, BlockAlign, BlockFlow } from 'common/components/Block';
import { BlackAndWhiteTextButton } from 'common/components/BlackAndWhiteTextButton';

import { IQuestion } from './types';
import { st, classes } from './Questions.st.css';

interface EditQuestionProps {
  index: number;
  question: IQuestion;
  forceFocus?: boolean;
  onRemove(id: string): void;
  onChange(question: IQuestion): void;
  onBlur(question: IQuestion): void;
}

export const EditQuestion: React.FC<EditQuestionProps> = (props) => {
  const { index, question, forceFocus, onRemove, onChange, onBlur } = props;
  const { t } = useTranslation();

  const handleTextChanged = (evt: any) => {
    onChange({
      ...question,
      text: evt.target.value,
    });
  };

  const handleBlur = () => {
    onBlur(question);
  };

  const handleRequiredChanged = (evt: any) => {
    onChange({
      ...question,
      required: evt.target.checked,
    });
  };

  const handleRemove = () => {
    onRemove(question.id!);
  };

  return (
    <Block flow={BlockFlow.row} className={st(classes.question)}>
      <Block
        flow={BlockFlow.col}
        align={BlockAlign.center}
        autoContent
        end={
          <BlackAndWhiteTextButton onClick={handleRemove}>
            <DeleteIcon />
          </BlackAndWhiteTextButton>
        }
      >
        <Text
          typography={TextTypography.runningText}
          tagName="p"
          className={classes.questionLabel}
        >
          {t('groups-web.membership-questions.question')} {index}
        </Text>
      </Block>
      <TextField
        className={classes.input}
        value={question.text!}
        placeholder={t('groups-web.membership-questions.question.placeholder')}
        onChange={handleTextChanged}
        onBlur={handleBlur}
        error={question.invalid}
        autoFocus={forceFocus}
      />
      <Checkbox
        onChange={handleRequiredChanged}
        label={t('groups-web.membership-questions.question.required.label')}
        className={classes.checkbox}
        checked={question.required!}
      />
    </Block>
  );
};

EditQuestion.displayName = 'EditQuestion';
