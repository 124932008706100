import React from 'react';
import { useSelector } from 'react-redux';
import { useBi, useTranslation } from '@wix/yoshi-flow-editor';
import { Text, TextTypography, TextFieldTheme } from 'wix-ui-tpa';
import { groupSettingsSave } from '@wix/bi-logger-groups/v2';

import { SwitchBlock } from 'common/components/Block';
import { BlackAndWhiteTextField } from 'common/components/BlackAndWhiteTextField';
import { useDidUpdate } from 'common/hooks';
import { useController } from 'common/context/controller';
import { BIUserEntry } from 'common/bi-logger/types';

import {
  selectAllApplications,
  selectAreApplicationsUpdating,
} from 'store/selectors';
import { GroupApp, GroupAppKey } from 'store/groups/types';

import { GroupSettingsContext } from '../../GroupSettingsContext';

import { classes as tabsClasses } from '../Tabs.st.css';
import { classes } from './ApplicationsTab.st.css';

interface IApplicationsTabProps {
  groupId: string;
  onClose(): void;
}

export const GroupTab = {
  [GroupAppKey.FEED_APP]: 'groups-web.group.settings.tabs.discussion',
  [GroupAppKey.GALLERY_APP]: 'groups-web.group.settings.tabs.media',
  [GroupAppKey.FILES_APP]: 'groups-web.group.settings.tabs.files',
  [GroupAppKey.MEMBERS_APP]: 'groups-web.group.settings.tabs.members',
  [GroupAppKey.ABOUT_APP]: 'groups-web.group.settings.tabs.about',
  [GroupAppKey.EVENTS_APP]: 'groups-web.group.settings.tabs.events',
  [GroupAppKey.CUSTOM_APP]: 'groups-web.group.settings.tabs.custom',
} as const;

const isAlwaysVisible = (key: GroupAppKey) =>
  key === GroupAppKey.FEED_APP || key === GroupAppKey.ABOUT_APP;

export function ApplicationsTab(props: IApplicationsTabProps) {
  const { t } = useTranslation();
  const { group$ } = useController();
  const bi = useBi();

  const groupSettings = React.useContext(GroupSettingsContext);

  const [applications, setApplications] = React.useState<GroupApp[]>([]);

  const isUpdating = useSelector(selectAreApplicationsUpdating(props.groupId));
  const savedGroupApps = useSelector(selectAllApplications(props.groupId));

  React.useEffect(() => {
    if (!isUpdating) {
      setApplications(savedGroupApps);
    }
  }, [savedGroupApps, isUpdating]);

  React.useEffect(() => {
    groupSettings.set({
      submitButtonProps: {
        loading: isUpdating,
        disabled: isUpdating || hasValidationError(),
        onClick: handleSave,
      },
    });
  }, [isUpdating, applications]);

  useDidUpdate(() => {
    if (!isUpdating) {
      props.onClose();
    }
  }, [isUpdating]);

  return (
    <div className={classes.root}>
      <Text
        tagName="p"
        className={tabsClasses.bodyText}
        typography={TextTypography.runningText}
      >
        {t('groups-web.group.settings.tabs.caption')}
      </Text>
      {applications.map((groupApp, index) => {
        const { key, installed } = groupApp;
        const tabName = getTabName(groupApp);
        return (
          <SwitchBlock
            onChange={() => switchTab(groupApp, index)}
            checked={installed!}
            disabled={isAlwaysVisible(key!)}
            key={key}
            className={classes.switchBlock}
            aria-label={`${tabName} ${t(
              'groups-web.group.settings.tabs.installed',
              {
                defaultValue: 'installed',
              },
            )}`}
          >
            <div>
              <BlackAndWhiteTextField
                maxLength={20}
                value={getTabName(groupApp)}
                showCharCount
                theme={TextFieldTheme.Line}
                onChange={(e) => changeName(groupApp, index, e.target.value)}
              />
            </div>
          </SwitchBlock>
        );
      })}
    </div>
  );

  function switchTab(groupApp: GroupApp, index: number) {
    const updatedApps = [...applications];
    updatedApps[index] = {
      ...groupApp,
      installed: !groupApp.installed,
    };
    setApplications(updatedApps);
  }

  function getTabName(groupApp: GroupApp): string {
    if (typeof groupApp.customName === 'undefined') {
      return t(GroupTab[groupApp.key!]);
    }
    return groupApp.customName!;
  }

  function changeName(groupApp: GroupApp, index: number, name: string) {
    const updatedApps = [...applications];
    updatedApps[index] = {
      ...groupApp,
      customName: name,
    };
    setApplications(updatedApps);
  }

  function handleSave() {
    bi.report(
      groupSettingsSave({
        userEntry: BIUserEntry.SITE,
        group_id: props.groupId,
        origin: 'save_button',
        tabName: 'tabs',
        show_media: getApplicationByKey(GroupAppKey.GALLERY_APP).installed!,
        show_members: getApplicationByKey(GroupAppKey.MEMBERS_APP).installed!,
        edit_tab: JSON.stringify({
          discussion: getApplicationByKey(GroupAppKey.FEED_APP).customName,
          media: getApplicationByKey(GroupAppKey.GALLERY_APP).customName,
          members: getApplicationByKey(GroupAppKey.MEMBERS_APP).customName,
          about: getApplicationByKey(GroupAppKey.ABOUT_APP).customName,
        }),
      }),
    );
    group$.configureApps(props.groupId, applications);
  }

  function getApplicationByKey(key: GroupAppKey) {
    return applications.find((app) => app.key === key) || {};
  }

  function hasValidationError() {
    const appsWithCustomName = applications.filter(
      (app) => typeof app.customName !== 'undefined',
    );
    // empty custom name
    return appsWithCustomName.some((app) => !app.customName?.trim());
  }
}
